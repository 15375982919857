import {
  Flex,
  Text,
  Divider,
  useColorModeValue,
  Select,
  SimpleGrid,
  StatLabel,
  Button,
  Stat,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  palette: {}
});
import { RepeatIcon, } from '@chakra-ui/icons'

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useMemo, useEffect, useState } from "react";
import TablaRoles from "views/Grillas/RolesGrilla";

import TablaObjetosRoles from "views/Grillas/ObjetosRolesGrilla";

function Roles() {
  const textColor = useColorModeValue("gray.700", "white");
  const [selectedRol, setSelectedRol] = useState('');
  const [selectedObjeto, setSelectedObjeto] = useState('');
  const [valoresLista, setValoresLista] = useState([]);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const loadvaloreslista = async () => {
    try {

      const seleccion = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/roles`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token'),
        },
      });
      const seleccionjson = await seleccion.json();
      //console.log("seleccionjson", seleccionjson);
      setValoresLista(seleccionjson);

      if (seleccionjson.length > 0) {
        setSelectedRol(seleccionjson[0].rol_codigo);
      }

    } catch (err) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  useEffect(() => { loadvaloreslista() }, []);

  const GuardarCambios = async () => {
    try {
      console.log('valores seleccionados en grilla:', selectedObjeto);
      const numeros = Object.keys(selectedObjeto);
      //borramos todas las relaciones primero
      const seleccion = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/rolesxobjetos/` + selectedRol, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token'),
        },
      });

      //luego las creamos a las que estan seleccionadas      
      numeros.map(numero => {
        //console.log("numero ", numero)
        const rest = fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/rolesxobjetos`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token'),
            },
            body: JSON.stringify({
              rol_codigo: selectedRol,
              obj_codigo: numero,
            })
          });
      });

      setShowErrorAlert(true);

      setTimeout(() => {
        setShowErrorAlert(false);
      }, 1000);


    } catch (err) {
      // Manejar el error de autenticación
      console.error('Error al guardar roles xobjetos:', err.message);
    }
  };

  const manejarDatoDesdeHijo = (dato) => {
    setSelectedObjeto(dato);
  };

  const handleSelectClick = (event) => {
    setSelectedRol(event.target.value);
  }
  //onChange={e => setSelectedRol(e.target.value)

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Roles del Sistema
          </Text>
          <Divider />
        </CardHeader>
        <CardBody>
        <ThemeProvider theme={theme}>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' mb='20px'>
            <Card minH='125px'>
              <Flex direction='column'>
                <TablaRoles />
              </Flex>
            </Card>
            <Card minH='125px'>
              <Flex direction='column'>
                <Text color={textColor} fontSize='lg' fontWeight='bold'>
                  Rol x Objetos
                </Text>
                <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing='24px' mb='20px'>
                  <Stat me='auto'>
                    <Card>
                      <SimpleGrid columns={{ sm: 2, md: 2, xl: 2 }} spacing='20px'>
                        <Select icon={<MdArrowDropDown />} variant="filled" value={selectedRol} onChange={handleSelectClick}  >
                          {valoresLista.map((dpto) => (
                            <option key={dpto.rol_codigo} value={dpto.rol_codigo}>
                              {dpto.rol_descripcion}
                            </option>
                          ))}
                        </Select>
                        <RepeatIcon w='25px' h='25px' cursor='pointer' 
                         onClick={loadvaloreslista}
                        />
                      </SimpleGrid>
                    </Card>
                  </Stat>
                  <Card>
                    <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' mb='20px'>
                      <Button 
                      variant='primary' 
                      fontSize='13px'
                      fontWeight='bold'
                      w='60%'
                      h='9'       
                      onClick={GuardarCambios} >
                        GUARDAR
                      </Button>

                      <div>
                        {showErrorAlert && (
                          <Alert status="success" onClose={() => setShowErrorAlert(false)}>
                            <AlertIcon />
                            Se actualizo el rol
                          </Alert>
                        )}
                      </div>

                    </SimpleGrid>
                    {selectedRol && <TablaObjetosRoles valorParaHijo={selectedRol} onEnviarDato={manejarDatoDesdeHijo} />}
                  </Card>
                </SimpleGrid>
              </Flex>
            </Card>
          </SimpleGrid>
        </ThemeProvider>
        </CardBody>
      </Card>
    </Flex >
  );
}

export default Roles;
