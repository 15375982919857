// Chakra imports
import {
  Flex,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import { mkConfig, generateCsv, download } from 'export-to-csv';

//////////nuevos
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DataLabel from "views/Cards/DataLabel";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { jsPDF } from 'jspdf';
import jsonToTable from 'json-to-table';

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import GrillaEffect from "views/Grillas/GrillaEffect";
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { TextField } from "@mui/material";
registerLocale("es", es); // register it with the name you want


const theme = createTheme({
  palette: {}
});

function RecursosHumanos() {
  
  ///exporta csv
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const GenerarCsv = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/21`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();
      // console.log(" get consulta ", resulte);          
      // Convierte la respuesta a formato JSON
      const csv = generateCsv(csvConfig)(resulte);
      download(csvConfig)(csv);

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  ///exporta pdf 
  const GenerarPdf = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/21`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();

      const tabled = jsonToTable(resulte);
      let nombresCampos = tabled[0];
      const doc = new jsPDF();

      doc.autoTable({
        head: [nombresCampos],
        body: tabled,
        margin: { top: 10 },
        styles: {
          minCellHeight: 4,
          halign: "left",
          valign: "center",
          fontSize: 4,
        },
      });
      doc.save('pdf-cobranzas.pdf');

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };
  const defaultFecha = [
    {id: 'periodo', value: '202403'}
  ];
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([...defaultFecha]);
  
  const url = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysextgrilla/`;


  const columnNames = {
    legajo: 'Legajo',
    dni: 'DNI',
    apellido_nombre: 'Nombre Completo',
    convenio: 'Convenio',
    inasistencias: 'Inasistencias',
    importe_neto: 'Neto',
    importe_ganancias: 'Ganancias',
  }

  const topToolbar = function(table) {
    return <Box>
      <TextField
          label="Desde"
          value={filters.find(i => i.id == 'periodo').value}
          onChange={(fecha) => {
            let items = [...filters];
            const x = items.findIndex(i => i.id == 'periodo')
            let item = {...items[x], value: fecha};
            items[x] = item;
            table.table.setColumnFilters(items)
          }}
      />
    </Box>
  }
  //////////////
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardBody size='sm'>
          <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='22px'>
            <GridItem colSpan={2}>
              <Card minH='125px'>
                <CardBody px='2px'>
                  <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='5px'>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card minH='125px'>
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Flex align='center'>
                      <Button                      
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='green'
                        onClick={GenerarCsv}
                      >
                        Exportar CSV
                      </Button>
                    </Flex>
                    <Flex align='center'>
                      <Button
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='red'
                        onClick={GenerarPdf}
                      >
                        Exportar PDF
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </GridItem>
          </Grid>
          <Card p='16px' my='5px'>
            <CardBody >
              <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Personal</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <ThemeProvider theme={theme}>
                        <GrillaEffect 
                          data={data} updateData={setData} 
                          filters={filters} updateFilters={setFilters}
                          colsHeaders={columnNames}
                          idRemoto={42}/>
                      </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                </TabPanels>
              </Tabs>

            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Flex>

  );
}

export default RecursosHumanos;