//import theme from "theme/theme.js";
import { useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
const theme = createTheme({
    palette: {}
  });
 
const estadoQuery =  ["HABILITADO","DESHABILITADO"];


const QueryGrilla = () => {
  const [validationErrors, setValidationErrors] = useState({});

  const columns = useMemo(
    () => [
      {
        accessorKey: 'que_codigo',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'que_descripcion',
        header: 'Descripcion',
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.que_descripcion,
          helperText: validationErrors?.que_descripcion,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              que_descripcion: undefined,
            }),
        },
      },           
      {
        accessorKey: 'que_etiqueta',
        header: 'Etiqueta',
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.que_etiqueta,
          helperText: validationErrors?.que_etiqueta,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              que_etiqueta: undefined,
            }),
        },
      },            
      {
        accessorKey: 'que_sql',
        header: 'Sql',
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.que_sql,
          helperText: validationErrors?.que_sql,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              que_sql: undefined,
            }),
        },
      },            
      {
        accessorKey: 'que_estado',
        header: 'Estado',
        editVariant: 'select',
        editSelectOptions: estadoQuery,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.que_estado,
          helperText: validationErrors?.que_estado,
        },
      },
      {
        accessorKey: 'que_fecha_baj',
        header: 'F.Baja',       
        enableEditing: false,        
      },  
      {
        accessorKey: 'que_fecha_alt',
        header: 'F.Alta',    
        enableEditing: false,   
      },  
      {
        accessorKey: 'que_fecha_mod',
        header: 'F.Modificacion',        
        enableEditing: false,
      },  
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    //console.log('validacion valore del rol',values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    //console.log('valore del rol',values);
    await createUser(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Esta seguro de eliminar el Query?')) {      
      deleteUser(row.original.que_codigo);
    }
  };


  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error al cargar Datos',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Nuevo Query</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Modificar Query</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Editar">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>        
         {/*
        <Tooltip title="Baja">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
         */}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          //inicia los valores para un nuevo registro
           table.setCreatingRow(
             createRow(table, { que_codigo:"",que_descripcion:"",que_estado : "HABILITADO",que_etiqueta:"",que_sql:"",
               //optionally pass in default values for the new row, useful for nested data or other complex scenarios               
             }),
           );
        }}
      >
        Nuevo Query
      </Button>
    ),
    tipo: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser ,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });
 
  return <MaterialReactTable table={table} />;
};

//CREATE hook (post new user to api)
function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {

      //send api update request here
      try {
        // Realiza la solicitud a la API
        console.log("datos nuevo rol " ,user);

        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querys`, 
            {method: "post",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },       
            body: JSON.stringify({que_descripcion: user.que_descripcion,   
                                  que_etiqueta: user.que_etiqueta,                                                                 
                                  que_sql: user.que_sql,
                                  que_estado: user.que_estado,
                                 })     
            })

        const  resulte = await rest.json();           
       // console.log(" post usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }

      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['objetos'], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['objetos'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['objetos'],
    queryFn: async () => {
      try {
        // Realiza la solicitud a la API
       
         const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querys`, 
            {method: "get",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },            
            })
            //.then(res => res.json())
          //.then(data => settotrechoy(data) );      
          const  resulte = await rest.json();           
      //  console.log(" get usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here

      try {
        // Realiza la solicitud a la API
       
        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querys/`+user.que_codigo, 
            {method: "put",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },       
            body: JSON.stringify({que_descripcion:user.que_descripcion,                                  
                                  que_etiqueta:user.que_etiqueta,                                  
                                  que_sql: user.que_sql,
                                  que_estado: user.que_estado,
                                 })     
            })

        const  resulte = await rest.json();           
       // console.log(" put usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['objetos'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        ),
      );
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['objetos'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (que_codigo) => {
      //send api update request here
      
      try {
        // Realiza la solicitud a la API       
       // console.log(" borar  usr_codigo ",usr_codigo);
        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querys/`+que_codigo, 
            {method: "delete",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },                        
            })

        const  resulte = await rest.json();           
        //console.log(" delete usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }

      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['objetos'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['objetos'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  
  <ThemeProvider theme={theme}>
  <QueryClientProvider client={queryClient}>
    <QueryGrilla />
  </QueryClientProvider>        
</ThemeProvider>
  
);

export default ExampleWithProviders;

const validateRequired = (value) => !!value.length;
const validateEmail = (value) =>{ 
  var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var regOficial = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  //Se muestra un texto a modo de ejemplo, luego va a ser un icono
  if (reg.test(value) && regOficial.test(value)) {
      return true;
  } else 
      return false;

};
function validateUser(user) {  
  return {
        
    que_descripcion: !validateRequired(user.que_descripcion) ? 'campo Requerido' : '',
    que_etiqueta: !validateRequired(user.que_etiqueta) ? 'campo Requerido' : '',
    que_sql: !validateRequired(user.que_sql) ? 'campo Requerido' : '',
    
  };
}
