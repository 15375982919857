// Chakra imports
import {
  Flex,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState, useEffect } from "react";
import gridColumns from "helpers/gridColumns";
import "react-datepicker/dist/react-datepicker.css";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, IconButton, Tooltip, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
//////////nuevos
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { jsPDF } from 'jspdf';
import jsonToTable from 'json-to-table';

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import dayjs from "dayjs";
import DataLabel from "views/Cards/DataLabel";
import { Cancel, Save } from "@mui/icons-material";
registerLocale("es", es); // register it with the name you want


const theme = createTheme({
  palette: {}
});

const GrillaEffect = ({
  data,
  updateData,
  filters,
  updateFilters,
  hiddenColumns = null,
  customTopToolbar = null,
  idRemoto = null,
  colsHeaders = [],
  useCustomFilter = false
}) => {
  //data and fetching state
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnOrder, setColumnOrder] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysextgrilla/`;
      // Objeto con los parámetros que deseas enviar                   
      const queryParams = {
        id: idRemoto ? idRemoto : 21,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters: JSON.stringify(filters ?? []),
        globalFilter: globalFilter ?? '',
        sorting: JSON.stringify(sorting ?? []),
        custom_filter: useCustomFilter
      };
      const urlWithParams = new URL(baseURL);
      Object.keys(queryParams).forEach(key => urlWithParams.searchParams.append(key, queryParams[key]));

      try {
        const response = await fetch(urlWithParams, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          }
        });
        const json = await response.json();
        updateData(json.data);
        setRowCount(json.meta.totalRowCount);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [
    filters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);
  const refreshIconComponent = function () {
    return <Tooltip arrow title="Actualiza Tabla">
      <IconButton onClick={() => fetchData()}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  };
  //const columns = gridColumns(data, colsHeaders);
  const columns = [

    {
      accessorKey: 'concepto',
      header: 'Concepto',
    },
    {
      accessorKey: 'importe_mes1',
      header: 'Mes 1',
      id: 'importe_mes1',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      accessorKey: 'importe_mes2',
      header: 'Mes 2',
      id: 'importe_mes2',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      accessorKey: 'importe_mes3',
      header: 'Mes 3',
      id: 'importe_mes3',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      accessorKey: 'importe_mes4',
      header: 'Mes 4',
      id: 'importe_mes4',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      accessorKey: 'importe_mes5',
      header: 'Mes 5',
      id: 'importe_mes5',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      accessorKey: 'importe_mes6',
      header: 'Mes 6',
      id: 'importe_mes6',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available),
    enableEditing: true,
    enableFilters: true,
    enableColumnFilters: true,
    initialState: {
      columnVisibility: hiddenColumns,
      showColumnFilters: true,
      columnFilters: filters
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100, //set custom width
        muiTableHeadCellProps: {
          align: 'center', //change head cell props
        },
        Cell: ({ cell, column, renderedCellValue, row, table }) => {
          const original = row.original;
          const isEditing = table.getState().editingRow?.id === row.id;
          return (
            isEditing ?
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip title="Edit">
                <IconButton onClick={() => table.setEditingRow(null)}>
                  <Save />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton color="error" onClick={() => table.setEditingRow(original)}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Box> :
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
          </Box>
          )
        }
      },
    },
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    muiTableBodyProps: {
      children: isLoading ? (
        <tr style={{ height: '200px' }}>
          <td />
        </tr>
      ) : undefined,
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: updateFilters,
    onColumnOrderChange: setColumnOrder,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: customTopToolbar == null ?
      refreshIconComponent : customTopToolbar
    ,
    rowCount,
    state: {
      filters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
}


function PresupuestoOperativo() {

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const colsHidden = {
    id: false
  };
  const columnNames = {
    concepto: 'Concepto',
    importe_mes1: 'Mes 1',
    importe_mes2: 'Mes 2',
    importe_mes3: 'Mes 3',
    importe_mes4: 'Mes 4',
    importe_mes5: 'Mes 5',
    importe_mes6: 'Mes 6',
  };

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const GenerarCsv = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/18`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();
      // console.log(" get consulta ", resulte);          
      // Convierte la respuesta a formato JSON
      const csv = generateCsv(csvConfig)(resulte);
      download(csvConfig)(csv);

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  ///exporta pdf 
  const GenerarPdf = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/18`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();

      const tabled = jsonToTable(resulte);
      let nombresCampos = tabled[0];
      const doc = new jsPDF();

      doc.autoTable({
        head: [nombresCampos],
        body: tabled,
        margin: { top: 10 },
        styles: {
          minCellHeight: 4,
          halign: "left",
          valign: "center",
          fontSize: 4,
        },
      });
      doc.save('pdf-cobranzas.pdf');

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardBody size='sm'>
          <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='22px'>
            <GridItem colSpan={2}>
              <Card minH='125px'>
                <CardBody px='2px'>
                  <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='5px'>

                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card minH='125px'>
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Flex align='center'>
                      <Button
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='green'
                        onClick={GenerarCsv}
                      >
                        Exportar CSV
                      </Button>
                    </Flex>
                    <Flex align='center'>
                      <Button
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='red'
                        onClick={GenerarPdf}
                      >
                        Exportar PDF
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </GridItem>
          </Grid>
          <Card p='16px' my='5px'>
            <CardBody >

              <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Presupuesto</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <ThemeProvider theme={theme}>
                        <GrillaEffect
                          data={data} updateData={setData}
                          filters={filters} updateFilters={setFilters}
                          idRemoto={38}
                          colsHeaders={columnNames}
                          hiddenColumns={colsHidden} />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                  <TabPanel>
                  </TabPanel>
                </TabPanels>
              </Tabs>

            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Flex>

  );
}

export default PresupuestoOperativo;
