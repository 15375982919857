import { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import gridColumns from "helpers/gridColumns";
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const GrillaEffect = ({
  data,
  updateData,
  filters,
  updateFilters,
  hiddenColumns = null,
  customTopToolbar = null,
  idRemoto = null,
  colsHeaders = [],
  useCustomFilter = false
}) => {
  //data and fetching state
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnOrder, setColumnOrder] = useState([]);
  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    let currentFilters = [...filters];
    currentFilters = currentFilters.filter(filter => {
      return Object.keys(colsHeaders).some(columnKey => columnKey === filter.id)
    });
    const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysextgrilla/`;
    // Objeto con los parámetros que deseas enviar                   
    const queryParams = {
      id: idRemoto ? idRemoto : 21,
      page: pagination.pageIndex,
      size: pagination.pageSize,
      filters: JSON.stringify(currentFilters ?? []),
      globalFilter: globalFilter ?? '',
      sorting: JSON.stringify(sorting ?? []),
      custom_filter: useCustomFilter
    };
    const urlWithParams = new URL(baseURL);
    Object.keys(queryParams).forEach(key => urlWithParams.searchParams.append(key, queryParams[key]));

    try {
      const response = await fetch(urlWithParams, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token'),
        }
      });
      const json = await response.json();
      updateData(json.data);
      setRowCount(json.meta.totalRowCount);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  useEffect(() => {
    fetchData();
  }, [
    filters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);
  const refreshIconComponent = function () {
    return <Tooltip arrow title="Actualiza Tabla">
      <IconButton onClick={() => fetchData()}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  };
  const columns = gridColumns(data, colsHeaders, filters);
  const table = useMaterialReactTable({
    columns,
    data,
    enableFilters: true,
    enableColumnFilters: true,
    initialState: {
      columnVisibility: hiddenColumns,
      showColumnFilters: true,
      columnFilters: filters
    },
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    muiTableBodyProps: {
      children: isLoading ? (
        <tr style={{ height: '200px' }}>
          <td />
        </tr>
      ) : undefined,
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: updateFilters,
    onColumnOrderChange: setColumnOrder,
    //onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: customTopToolbar == null ?
      refreshIconComponent : customTopToolbar
    ,
    rowCount,
    state: {
      filters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
}

export default GrillaEffect;