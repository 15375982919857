// Chakra imports
import {
  Flex,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { mkConfig, generateCsv, download } from 'export-to-csv';

//////////nuevos
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { jsPDF } from 'jspdf';
import jsonToTable from 'json-to-table';

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import GrillaEffect from "views/Grillas/GrillaEffect";
import dayjs from "dayjs";
import DataLabel from "views/Cards/DataLabel";
registerLocale("es", es); // register it with the name you want


const theme = createTheme({
  palette: {}
});

function PresupuestoFinanciero() {

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);

  const columnNames = {
    concepto: 'Concepto',
    importe_mes1: 'Mes 1',
    importe_mes2: 'Mes 2',
    importe_mes3: 'Mes 3',
    importe_mes4: 'Mes 4',
    importe_mes5: 'Mes 5',
    importe_mes6: 'Mes 6',
  };
  
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const GenerarCsv = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/18`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();
      // console.log(" get consulta ", resulte);          
      // Convierte la respuesta a formato JSON
      const csv = generateCsv(csvConfig)(resulte);
      download(csvConfig)(csv);

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  ///exporta pdf 
  const GenerarPdf = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/18`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();

      const tabled = jsonToTable(resulte);
      let nombresCampos = tabled[0];
      const doc = new jsPDF();

      doc.autoTable({
        head: [nombresCampos],
        body: tabled,
        margin: { top: 10 },
        styles: {
          minCellHeight: 4,
          halign: "left",
          valign: "center",
          fontSize: 4,
        },
      });
      doc.save('pdf-cobranzas.pdf');

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardBody size='sm'>
          <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='22px'>
            <GridItem colSpan={2}>
              <Card minH='125px'>
                <CardBody px='2px'>
                  <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='5px'>
                    
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card minH='125px'>
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Flex align='center'>
                      <Button                      
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='green'
                        onClick={GenerarCsv}
                      >
                        Exportar CSV
                      </Button>
                    </Flex>
                    <Flex align='center'>
                      <Button
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='red'
                        onClick={GenerarPdf}
                      >
                        Exportar PDF
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </GridItem>
          </Grid>
          <Card p='16px' my='5px'>
            <CardBody >

              <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Presupuesto</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <ThemeProvider theme={theme}>
                        <GrillaEffect 
                          data={data} updateData={setData} 
                          filters={filters} updateFilters={setFilters}
                          idRemoto={39}
                          colsHeaders={columnNames}/>
                      </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                  <TabPanel>
                  </TabPanel>
                </TabPanels>
              </Tabs>

            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Flex>

  );
}

export default PresupuestoFinanciero;
