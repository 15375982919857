//import theme from "theme/theme.js";
import { useMemo, useState ,useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable,
} from 'material-react-table';


import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
const theme = createTheme({
    palette: {}
  });

const tipousr =  ["INTERNO","EXTERNO", "SISTEMAS"]; 
const estadousr =  ["HABILITADO","DESHABILITADO", "BLOQUEADO"];

const UsuarioGrilla = () => {
  const [columns, setColumns] = useState([]);

  const [validationErrors, setValidationErrors] = useState({}); 

  const [habilitacampo, sethabilitacampo] = useState(true); 

  
/*
  const columns = useMemo(
    () => [
      {
        accessorKey: 'usr_codigo',
        header: 'Usuario',
        enableEditing: habilitacampo,   
        disableEdit: !habilitacampo  && table?.editingRow , 
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.usr_codigo,
          helperText: validationErrors?.usr_codigo,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              usr_codigo: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'usr_descripcion',
        header: 'Descripcion',
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.usr_descripcion,
          helperText: validationErrors?.usr_descripcion,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              usr_descripcion: undefined,
            }),
        },
      },
 
      {
        accessorKey: 'usr_tipo',
        header: 'Tipo',
        editVariant: 'select',
        editSelectOptions: tipousr,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.usr_tipo,
          helperText: validationErrors?.usr_tipo,
        },
      },
      {
        accessorKey: 'usr_email',
        header: 'Email',
        muiEditTextFieldProps: {
          type: 'email',
          required: true,
          error: !!validationErrors?.usr_email,
          helperText: validationErrors?.usr_email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              usr_email: undefined,
            }),
        },
      },  
      {
        accessorKey: 'usr_estado',
        header: 'Estado',
        editVariant: 'select',
        editSelectOptions: estadousr,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.usr_estado,
          helperText: validationErrors?.usr_estado,
        },
      },
      {
        accessorKey: 'usr_fecha_baj',
        header: 'F.Baja',       
        enableEditing: false,        
      },  
      {
        accessorKey: 'usr_fecha_alt',
        header: 'F.Alta',    
        enableEditing: false,   
      },  
      {
        accessorKey: 'usr_fecha_mod',
        header: 'F.Modificacion',        
        enableEditing: false,
      },  
    ],
    [validationErrors],
  ); 
*/
  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();
  //cambio clave
    const { mutateAsync: cambioUser, isPending: isCambioUser } =
    useCambioUser();  

  //CREATE action
  const handleCreateUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Esta seguro de eliminar el usuario?')) {      
      deleteUser(row.original.usr_codigo);
    }
  };

  const openCambioContrasena = (row) => {
    if (window.confirm('Esta seguro de resetear contraseña?')) {      
      cambioUser(row.original.usr_codigo);
    }
  };

  const table = useMaterialReactTable({
    columns ,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error al cargar Datos',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Nuevo Usuario</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents})  => (           
      <>
        <DialogTitle variant="h4">Modificar Usuario</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Editar">
          <IconButton onClick={ () => editarregistro(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Contraseña">          
          <IconButton color="error" onClick={() => openCambioContrasena(row)}>
            <KeyIcon />
          </IconButton>
        </Tooltip>
         {/*
        <Tooltip title="Baja">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
         */}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
      //variant='primary'
        variant="contained"      

        onClick={() => {
          sethabilitacampo(true);           

          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          //inicia los valores para un nuevo registro
           table.setCreatingRow(
             createRow(table, { usr_codigo:"",usr_descripcion:"",usr_tipo : "INTERNO",usr_email:"",usr_estado : "HABILITADO",
               //optionally pass in default values for the new row, useful for nested data or other complex scenarios               
             }),
           );
        }}
      >
        Nuevo Usuario
      </Button>
    ),
    tipo: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser ||isCambioUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });         

  const editarregistro = (row) => {
    //console.log("paso 555555555555555 ");
    sethabilitacampo(false);         
    table.setEditingRow(row)
    //console.log("paso 66666666666666 ");    
  }


  useEffect(() => {
    setColumns([
      {
        accessorKey: 'usr_codigo',
        header: 'Usuario',
        enableEditing: habilitacampo,
        disableEdit: !habilitacampo && table?.editingRow,
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.usr_codigo,
          helperText: validationErrors?.usr_codigo,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              usr_codigo: undefined,
            }),
        },
      },
      {
        accessorKey: 'usr_descripcion',
        header: 'Descripcion',
        muiEditTextFieldProps: {
          type: 'string',
          required: true,
          error: !!validationErrors?.usr_descripcion,
          helperText: validationErrors?.usr_descripcion,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              usr_descripcion: undefined,
            }),
        },
      },
  
      {
        accessorKey: 'usr_tipo',
        header: 'Tipo',
        editVariant: 'select',
        editSelectOptions: tipousr,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.usr_tipo,
          helperText: validationErrors?.usr_tipo,
        },
      },
      {
        accessorKey: 'usr_email',
        header: 'Email',
        muiEditTextFieldProps: {
          type: 'email',
          required: true,
          error: !!validationErrors?.usr_email,
          helperText: validationErrors?.usr_email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              usr_email: undefined,
            }),
        },
      },  
      {
        accessorKey: 'usr_estado',
        header: 'Estado',
        editVariant: 'select',
        editSelectOptions: estadousr,
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.usr_estado,
          helperText: validationErrors?.usr_estado,
        },
      },
      {
        accessorKey: 'usr_fecha_baj',
        header: 'F.Baja',       
        enableEditing: false,        
      },  
      {
        accessorKey: 'usr_fecha_alt',
        header: 'F.Alta',    
        enableEditing: false,   
      },  
      {
        accessorKey: 'usr_fecha_mod',
        header: 'F.Modificacion',        
        enableEditing: false,
      },  
    ]);
  }, [habilitacampo, table?.editingRow, validationErrors]);
  

  return <MaterialReactTable table={table} />;
};




//CREATE hook (post new user to api)
function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      try {
        // Realiza la solicitud a la API
     //  console.log("datos nuevo usr " ,user);
        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/usuarios`, 
            {method: "post",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },       
            body: JSON.stringify({usr_codigo: user.usr_codigo,
                                  usr_descripcion: user.usr_descripcion,                                  
                                  usr_tipo: user.usr_tipo,
                                  usr_email: user.usr_email,
                                  usr_estado: user.usr_estado,
                                 })     
            })

        const  resulte = await rest.json();           
       // console.log(" post usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON                
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      try {
        // Realiza la solicitud a la API
       
         const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/usuarios`, 
            {method: "get",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },            
            })
            //.then(res => res.json())
          //.then(data => settotrechoy(data) );      
          const  resulte = await rest.json();           
      //  console.log(" get usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here

      try {
        // Realiza la solicitud a la API
       
        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/usuarios/`+user.usr_codigo, 
            {method: "put",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },       
            body: JSON.stringify({usr_descripcion:user.usr_descripcion,
                                  usr_tipo:user.usr_tipo,
                                  usr_email:user.usr_email,
                                  usr_estado: user.usr_estado,
                                 })     
            })

        const  resulte = await rest.json();           
       // console.log(" put usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        ),
      );
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (usr_codigo) => {
      //send api update request here
      
      try {
        // Realiza la solicitud a la API       
       // console.log(" borar  usr_codigo ",usr_codigo);
        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/usuarios/`+usr_codigo, 
            {method: "delete",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },                        
            })

        const  resulte = await rest.json();           
        //console.log(" delete usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//reset contraseña hook (delete user in api)
function useCambioUser() {  
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (usr_codigo) => {
      //console.log('ususario xxxxxxxxxxx ',usr_codigo);
      //send api update request here      
      try {
        // Realiza la solicitud a la API
       
        const rest = await fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/resetpass/`+usr_codigo, 
            {method: "put",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                      },       
            body: JSON.stringify({usr_clave:usr_codigo                                 
                                 })     
            })

        const  resulte = await rest.json();           
       // console.log(" put usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }

      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
     onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

queryClient.isFetching

const ExampleWithProviders = () =>    
  (
  //Put this with your other react-query providers near root of your app  
  <ThemeProvider theme={theme}>
  <QueryClientProvider client={queryClient}>
    <UsuarioGrilla/>
  </QueryClientProvider>        
</ThemeProvider>  
);

export default ExampleWithProviders;

const validateRequired = (value) => !!value.length;
const validateEmail = (value) =>{ 
  var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var regOficial = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  //Se muestra un texto a modo de ejemplo, luego va a ser un icono
  if (reg.test(value) && regOficial.test(value)) {
      return true;
  } else 
      return false;

};
function validateUser(user) {  
  return {    
    usr_codigo: !validateRequired(user.usr_codigo)? 'Campo Requerido': '',
    usr_descripcion: !validateRequired(user.usr_descripcion) ? 'campo Requerido' : '',
    usr_email: !validateEmail(user.usr_email) ? 'Formato Incorrecto ' : '',
  };
}
