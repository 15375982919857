// Chakra imports
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,  
  Text,
  Icon,
  useColorModeValue,
  Link as ChakraLink, 
  LinkBox, 
  LinkOverlay,
  LinkProps
} from "@chakra-ui/react";
import { LuCircleDollarSign } from "react-icons/lu";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaInfo } from "react-icons/fa";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link as ReactRouterLink } from 'react-router-dom'
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import IconBox from "components/Icons/IconBox";
import moneyFormat from "helpers/moneyFormat";
import timeFormat from "helpers/timeFormat";
// Custom icons
import {
  GlobeIcon
} from "components/Icons/Icons.js";
import React,{ useState, useEffect,useRef }  from "react";

// Variables
const monthNames = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];
const getNextMonthName = () => {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  return monthNames[nextMonth.getMonth()];
};

const getMonthName = () => {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return monthNames[nextMonth.getMonth()];
};

export default function Dashboard() {
  //#region Total Suministros Energia
  function loadTotSE(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/13`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>setTotSE(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [TotSE, setTotSE] = useState([]);
  useEffect(()=>{loadTotSE()},[]);
  //#endregion
  //#region Total Suministros Sepelios
  function loadTotSS(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/14`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>setTotSS(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [TotSS, setTotSS] = useState([]);
  useEffect(()=>{loadTotSS()},[]);
  //#endregion
  //#region Total Socios
  function loadTotSocios(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/12`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>setTotSoc(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [TotSoc, setTotSoc] = useState([]);
  useEffect(()=>{loadTotSocios()},[]);
  //#endregion
  //#region Total Clientes
  function loadTotClientes(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/11`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>setTotCli(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [TotCli, setTotCli] = useState([]);
  useEffect(()=>{loadTotClientes()},[]);
  //#endregion
  //#region Total Deuda
  function loadImpDeudaVencida(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/10`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>setImpDeuda(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [impDeuda, setImpDeuda] = useState([]);
  useEffect(()=>{loadImpDeudaVencida()},[]);
  //#endregion
  //#region Total Pagos
  function loadImpPagos(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/9`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>setImpPagos(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [impPagos, setImpPagos] = useState([]);
  useEffect(()=>{loadImpPagos()},[]);
  //#endregion
  //#region Total Facturado Hoy
  function loadtotfachoy(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/8`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>settotfachoy(data));                
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [totfachoy, settotfachoy] = useState([]);
  useEffect(()=>{loadtotfachoy()},[]);
  //#endregion
  //#region Total Recaudado hoy
  function loadtotrechoy(){
    try {                  
       fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/6`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },          
          })
          .then(res => res.json())
          .then(data =>settotrechoy(data));
    } catch (error) {
      console.error('Error al obtener datos:', err.message);
    }
  };
  const [totrechoy, settotrechoy] = useState([]);
  useEffect(()=>{loadtotrechoy()},[]);
  //#endregion
  
  //#region Grafico Batch
  function loadFactuBatch(){        
    fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/15`, 
          {method: "post",
          headers: { "Content-Type": "application/json" ,
                    "Authorization":  localStorage.getItem('token'),
                    },
          body: JSON.stringify({id:"320"})
          })
    .then(res => res.json())
    .then((data) => {      
      data = data.qre_resultado;

      const vtasArr = [];
      const optArr = [];
      for (let index = 0; index < data.length; index++) {   
        const item = data[index];
        vtasArr.push(item.ImporteTotal);  
        optArr.push(item.Mes);        
      }     
      const barChartData = [
        {
          name: "Facturado",
          data: vtasArr,
        },
      ];  
      // etiquetas
      const barChartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          theme: "dark",
        },
        xaxis: {
          categories :optArr,
          labels: {
            style: {
              colors: "#A0AEC0",
              fontSize: "12px",
            },
          },
          show: true,
          axisBorder: {
            show: false,
          },
          
        },
        yaxis: {
          show: true,
          color: "#A0AEC0",
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "14px",
            },
            formatter: (value) => {
              return moneyFormat(value);
            },
          },
        },
        fill: {
          colors: "#ED8936",
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          strokeDashArray: 5,
        },
        plotOptions: {
          bar: {
            borderRadius: 15,
            columnWidth: "15px",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 0,
                },
              },
            },
          },
        ],
      };
      
      setchartDataBatch(barChartData);
      setchartOptionsBatch(barChartOptions);
    });
    
  };
  const [chartOptionsBatch, setchartOptionsBatch] = useState([]);
  const [chartDataBatch, setchartDataBatch] = useState([]);
  useEffect(()=>{loadFactuBatch()},[]);
  //#endregion
  //#region Grafico Recaudado
  function loadRecaudacion(){        
    fetch(`https://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/16`, 
          {
            method: "post",
            headers: { "Content-Type": "application/json" ,
                      "Authorization":  localStorage.getItem('token'),
                    }
    })
    .then(res => res.json())
    .then((data) => {      
      data = data.qre_resultado;

      const vtasArr = [];
      const optArr = [];
      for (let index = 0; index < data.length; index++) {   
        const item = data[index];
        vtasArr.push(item.ImporteTotal);  
        optArr.push(item.Mes);        
      }     
      const barChartData = [
        {
          name: "Recaudado",
          data: vtasArr,
        },
      ];  
      // etiquetas
      const barChartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          theme: "dark",
        },
        xaxis: {
          categories :optArr,
          labels: {
            style: {
              colors: "#A0AEC0",
              fontSize: "12px",
            },
          },
          show: true,
          axisBorder: {
            show: false,
          },
          
        },
        yaxis: {
          show: true,
          color: "#A0AEC0",
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "14px",
            },
            formatter: (value) => {
              return moneyFormat(value);
            },
          },
        },
        fill: {
          colors: "#ED8936",
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          strokeDashArray: 5,
        },
        plotOptions: {
          bar: {
            borderRadius: 15,
            columnWidth: "15px",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 0,
                },
              },
            },
          },
        ],
      };
      
      setchartDataRecauda(barChartData);
      setchartOptionsRecauda(barChartOptions);
    });
    
  };
  const [chartOptionsRecauda, setchartOptionsRecauda] = useState([]);
  const [chartDataRecauda, setchartDataRecauda] = useState([]);
  useEffect(()=>{loadRecaudacion()},[]);
  //#endregion
  
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
 
  return ( 
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <LinkBox as='article'>
          <LinkOverlay as={ReactRouterLink} to='/admin/batch'>
            <Card minH='125px'>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                >
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      FACTURACION
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {totrechoy.qre_resultado ? moneyFormat(totrechoy.qre_resultado[0].recaudado) : "0"}
                      </StatNumber>
                    </Flex>
                    <StatHelpText fontSize='sm' mt='15px'>
                      Total Facturado con vencimiento {getNextMonthName()}
                    </StatHelpText>
                  </Stat>              
                  <IconBox
                    borderRadius='50%'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <Icon as={LuCircleDollarSign}  h={"24px"} w={"24px"} color={iconBoxInside} />
                  </IconBox>              
                </Flex>            
              </Flex>
            </Card>
          </LinkOverlay>
        </LinkBox>
        <LinkBox as='article'>
          <LinkOverlay as={ReactRouterLink} to='/admin/cobranzas'>
            <Card minH='125px'>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                >
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      COBRANZA
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {totfachoy.qre_resultado ? moneyFormat(totfachoy.qre_resultado[0].ImporteFacturado) : "0"}
                      </StatNumber>
                    </Flex>
                    <StatHelpText fontSize='sm' mt='15px'>
                      Cobranza cerrada al mes de {getMonthName()}
                    </StatHelpText>
                  </Stat>              
                  <IconBox
                    borderRadius='50%'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <Icon as={LuCircleDollarSign}  h={"24px"} w={"24px"} color={iconBoxInside} />
                  </IconBox>              
                </Flex>            
              </Flex>
            </Card>
          </LinkOverlay>
        </LinkBox>
        <LinkBox as='article'>
          <LinkOverlay as={ReactRouterLink} to='/admin/presupuesto_financiero'>
            <Card minH='125px'>
            <Flex direction='column'>
              <Flex
                flexDirection='row'
                align='center'
                justify='center'
                w='100%'
              >
                <Stat me='auto'>
                  <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    RECAUDACION
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {impPagos.qre_resultado ? moneyFormat(impPagos.qre_resultado[0].ImportePagado) : "0"}
                    </StatNumber>
                  </Flex>
                  <StatHelpText fontSize='sm' mt='15px'>
                    Recaudacion cerrada al mes de {getMonthName()}
                  </StatHelpText>
                </Stat>              
                <IconBox
                  borderRadius='50%'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <Icon as={LuCircleDollarSign}  h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>              
              </Flex>            
            </Flex>
            </Card>
          </LinkOverlay>
        </LinkBox>
        <LinkBox as='article'>
          <LinkOverlay as={ReactRouterLink} to='/admin/deudavencida'>
            <Card minH='125px'>
              <Flex direction='column'>
                <Flex
                  flexDirection='row'
                  align='center'
                  justify='center'
                  w='100%'
                >
                  <Stat me='auto'>
                    <StatLabel
                      fontSize='xs'
                      color='gray.400'
                      fontWeight='bold'
                      textTransform='uppercase'>
                      DEUDA
                    </StatLabel>
                    <Flex>
                      <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                      {impDeuda.qre_resultado ? moneyFormat(impDeuda.qre_resultado[0].ImporteDeuda) : "0"}
                      </StatNumber>
                    </Flex>
                    <StatHelpText fontSize='sm' mt='15px'>
                      Deuda cerrada al mes de {getMonthName()}
                    </StatHelpText>
                  </Stat>              
                  <IconBox
                    borderRadius='50%'
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}>
                    <Icon as={LuCircleDollarSign}  h={"24px"} w={"24px"} color={iconBoxInside} />
                  </IconBox>              
                </Flex>            
              </Flex>
            </Card>
          </LinkOverlay>
        </LinkBox>
      </SimpleGrid>
      <Grid
        templateColumns={{  lg: "1fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
          
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
              <ChakraLink as={ReactRouterLink} to='/admin/batch' fontSize='lg' fontWeight='bold'>
                Facturacion Batch <ExternalLinkIcon mx='2px' />
              </ChakraLink>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={chartDataBatch} chartOptions={chartOptionsBatch} />
          </Box>
        </Card>

        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <ChakraLink as={ReactRouterLink} to='/admin/cobranzas' fontSize='lg' fontWeight='bold'>
                Recaudacion <ExternalLinkIcon mx='2px' />
            </ChakraLink>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={chartDataRecauda} chartOptions={chartOptionsRecauda} />
          </Box>
        </Card>                
      </Grid>

      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Total Clientes
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {TotCli.qre_resultado ? TotCli.qre_resultado[0].cantCli : "0"}
                  </StatNumber>
                </Flex>
                <StatHelpText fontSize='sm' mt='15px'>
                  {TotCli.qre_resultado ? timeFormat(TotCli.qre_ejecucion) : null}
                </StatHelpText>
              </Stat>              
              <IconBox
                borderRadius='50%'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <Icon as={FaPeopleGroup}  h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>              
            </Flex>            
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Total Socios Act.
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {TotSoc.qre_resultado ? TotSoc.qre_resultado[0].cantSoc : "0"}
                  </StatNumber>
                </Flex>
                <StatHelpText fontSize='sm' mt='15px'>
                  {TotSoc.qre_resultado ? timeFormat(TotSoc.qre_ejecucion) : null}
                </StatHelpText>
              </Stat>              
              <IconBox
                borderRadius='50%'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <Icon as={FaPeopleGroup}  h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>              
            </Flex>            
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Total Sum. Energia
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {TotSE.qre_resultado ? TotSE.qre_resultado[0].cantidad : "0"}
                  </StatNumber>
                </Flex>
                <StatHelpText fontSize='sm' mt='15px'>
                  {TotSE.qre_resultado ? timeFormat(TotSE.qre_ejecucion) : null}
                </StatHelpText>
              </Stat>              
              <IconBox
                borderRadius='50%'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <Icon as={FaInfo }  h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>              
            </Flex>            
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
            >
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Total Sum. Sepelio
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {TotSS.qre_resultado ? TotSS.qre_resultado[0].cantidad : "0"}
                  </StatNumber>
                </Flex>
                <StatHelpText fontSize='sm' mt='15px'>
                  {TotSS.qre_resultado ? timeFormat(TotSS.qre_ejecucion) : null}
                </StatHelpText>
              </Stat>              
              <IconBox
                borderRadius='50%'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <Icon as={FaInfo }  h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>              
            </Flex>            
          </Flex>
        </Card>
      </SimpleGrid>

    </Flex>
  );
}
